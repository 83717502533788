import React from 'react';
import { Media } from 'gatsby-plugin-fresnel';
import classnames, { Argument } from 'classnames';
import StarIcon from '../images/ui/icon-star.inline.svg';

export type Tab = {
    label: string;
    content: JSX.Element | JSX.Element[] | string;
};

type TabsProps = {
    className?: Argument[];
    title?: string;
    tabs: Tab[];
}

const Tabs = ({ className, title, tabs }: TabsProps) => {
    const [activeTab, setActiveTab] = React.useState(0);
    const [chosenTab, setChosenTab] = React.useState<number|null>(null);

    const tabsClassName = classnames(['tabs'], className);

    return (
        <>
            <Media greaterThanOrEqual="l">
                <div className={tabsClassName}>
                    {title &&
                        <div className="tabs__title">
                            {title}
                        </div>
                    }
                    <div className="tabs__buttons">
                        {tabs.map((tab, index) => {
                            return (
                                <button
                                    className={classnames([
                                        'tabs__item',
                                        activeTab === index && 'tabs__item--active',
                                    ])}
                                    onClick={() => setActiveTab(index)}
                                    key={index}
                                >
                                    <StarIcon />
                                    {tab.label}
                                </button>
                            );
                        })}
                    </div>
                    <div className="tabs__content">
                        {tabs.map((tab, index) =>
                            <div
                                className={classnames([
                                    'tabs__content-item',
                                    index === activeTab && 'tabs__content-item--active',
                                ])}
                                key={index}
                            >
                                {tab.content}
                            </div>
                        )}
                    </div>
                </div>
            </Media>
            <Media lessThan="l">
                <div className={tabsClassName}>
                    {title &&
                        <div className="tabs__title">
                            {title}
                        </div>
                    }
                    {tabs.map((tab, index) => {
                        return (
                            <div className="tabs__carousel-item" key={index}>
                                <button
                                    className={classnames([
                                        'tabs__carousel-button',
                                        chosenTab === index && 'tabs__carousel-button--active',
                                        'chromeless',
                                    ])}
                                    onClick={() => {
                                        if (chosenTab === index) {
                                            setChosenTab(null);
                                        } else {
                                            setChosenTab(index);
                                        }
                                    }}
                                    key={index}
                                >
                                    {tab.label}
                                </button>
                                <div
                                    className={classnames([
                                        'tabs__carousel-content',
                                        chosenTab === index && 'tabs__carousel-content--active',
                                    ])}
                                >
                                    {tab.content}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Media>
        </>
    );
}

export default Tabs;