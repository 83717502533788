import React from 'react';
import { graphql, PageProps } from 'gatsby'
import classnames from 'classnames';
import parse from 'html-react-parser';
import Layout from '../components/Layout';
import NotFoundPage from '../pages/404';
import { DefaultGalleryListingItemProps, GridItem, ListItem } from '../components/Gallery';
import IconGrid from '../images/ui/icon-grid.inline.svg';
import IconList from '../images/ui/icon-list.inline.svg';
import Tabs, { Tab } from '../components/Tabs';
import { WorldsFairHead } from '../components/Head';
import { GatsbyImage } from 'gatsby-plugin-image';

const displayModes = ['grid', 'list'] as const;
type DisplayModes = typeof displayModes[number];

const DisplayModeListingComponents: { [key in DisplayModes]: React.FC<DefaultGalleryListingItemProps> } = {
    grid: GridItem,
    list: ListItem,
}

const DisplayModeIcons: { [key in DisplayModes]: any } = {
    grid: IconGrid,
    list: IconList,
}

const Gallery = ({ data: { gallery, artworks } }: PageProps<Queries.GalleryTemplateQuery>) => {
    if (!gallery || !artworks?.nodes) {
        return <NotFoundPage />;
    }

    const {
        slug: gallerySlug,
        tghpworldsfairTitle: title,
        tghpworldsfairIntro: intro,
        tghpworldsfairComingSoonTitle: comingSoonTitle,
        tghpworldsfairComingSoonImages: comingSoonImages,
        tghpworldsfairFaqs: faqs,
    } = gallery;

    const {
        nodes: galleryArtworks,
    } = artworks;

    const [displayMode, setDisplayMode] = React.useState<DisplayModes>('grid');
    const DisplayListingComponent = DisplayModeListingComponents[displayMode];

    let displayTabs: Tab[] = [];
    if (faqs) {
        for (const faq of faqs) {
            if (faq && faq.tghpworldsfairQuestion && faq.tghpworldsfairAnswer) {
                const {
                    tghpworldsfairQuestion: question,
                    tghpworldsfairAnswer: answer,
                } = faq;

                displayTabs.push({
                    label: question,
                    content: (
                        <>
                            <h2>{question}</h2>
                            {parse(answer)}
                        </>
                    ),
                });
            }
        }
    }

    return (
        <Layout location="gallery" showSignup={true} seoData={gallery.seo}>
            <header>
                <h1>
                    <span>{title}</span>
                    <div className="gallery-main-switcher">
                        {displayModes.map((mode, index) => {
                            const Icon = DisplayModeIcons[mode];

                            return (
                                <button
                                    onClick={() => setDisplayMode(mode)}
                                    className={classnames([
                                        'gallery-main-switcher__item',
                                        displayMode === mode && 'gallery-main-switcher__item--active',
                                    ])}
                                    key={mode}>
                                    <Icon />
                                </button>
                            );
                        })}
                    </div>
                </h1>
                <p>{intro}</p>
            </header>
            <div className="gallery-main">

                <div className={`gallery-main__items gallery-main__items--${displayMode}`}>
                    {gallerySlug && galleryArtworks.map((galleryArtwork, index) =>
                        galleryArtwork && gallerySlug &&
                            <DisplayListingComponent
                                {...galleryArtwork}
                                lazy={index > 3}
                                gallerySlug={gallerySlug}
                                key={index}
                            />
                    )}
                </div>
            </div>
            {comingSoonTitle &&
                <div className="coming-soon">
                    <h2 className="coming-soon__title">{comingSoonTitle}</h2>
                    {comingSoonImages?.nodes &&
                        <div className="coming-soon__images">
                            {comingSoonImages?.nodes.map((image, index) =>
                                image?.gatsbyImage && <GatsbyImage image={image?.gatsbyImage} alt="" key={index} loading="lazy" />
                            )}
                        </div>
                    }
                </div>
            }
            {displayTabs.length > 0 && <Tabs title="FAQ" tabs={displayTabs} />}
        </Layout>
    );
}

export default Gallery;

export const query = graphql`
    query GalleryTemplate ($id: Int!) {
        gallery: wpPage(databaseId: { eq: $id }) {
            slug
            tghpworldsfairTitle
            tghpworldsfairIntro
            tghpworldsfairComingSoonTitle
            tghpworldsfairComingSoonImages {
                nodes {
                    gatsbyImage(layout: FULL_WIDTH, width: 300, sizes: "300px")
                }
            }
            tghpworldsfairFaqs {
                tghpworldsfairQuestion
                tghpworldsfairAnswer
            }
            ...SeoData
        }
        
        artworks: allWpArtwork (
            sort: {
                fields: [menuOrder],
                order: [ASC]
            },
            filter: {
                featuredImageId: { ne: null }
            }
        ) {
            nodes {
                ...ArtworkData
            }
        }
    }
`

export const Head = () => <WorldsFairHead />